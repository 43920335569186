@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;300;400;500;600;700;800;900&family=Nunito:wght@300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans', sans-serif;
  position: relative;
  height: 100%;
}

:root {
  --main-gray: rgba(0, 0, 0, 0.6);
}

/* scrollbar */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
a {
  text-decoration: none;
  color: var(--main-gray);
}

.red {
  color: #ff0000;
  opacity: 90%;
}

#active a {
  color: #05092c;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

header .container {
  overflow: hidden;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  padding: 0;
  /* overflow-x: hidden; */
}

.hero {
  position: relative;
}
.logo {
  width: 130%;
  margin-top: 0.3rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 2rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.nav-items {
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-right: 2rem;
}

.nav-items li {
  font-weight: 500;
  color: var(--main-gray);
  margin: 2rem;
  font-size: 1.3rem;
}

/* The dropdown container */
.dropdown {
  float: left;
  /* overflow: hidden; */
  z-index: 3;
}

/* Dropdown button */
.dropdown .dropbtn {
  /* font-size: 16px; */
  border: none;
  outline: none;

  padding: 14px 0;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  top: 80%;
  left: 14%;
  text-wrap: nowrap;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 1rem;
}

.dropdown-content span {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 1rem;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ffdb58;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.gray {
  color: #888 !important;
}

.gray:hover {
  color: black !important;
}
/* Style for the accordion */
.accordion-main {
  margin: 0 !important;
  padding: 0 !important;
  border: 2px solid black;
  width: 100% !important;
  background-color: transparent !important;
}

.accordion-content {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.accordion-3 {
  margin: 0 !important;
  padding: 0 !important;
}

/****************************** end navbar  *************************/

.header-text-container {
  text-align: center;
}

.intro {
  font-weight: 800;
  width: 55%;
  font-size: 2.1rem;
  margin: 2rem auto;

  color: #05092c;
  line-height: 1.4;
}

.sub-intro {
  color: 05092C;
  width: 55%;
  font-size: 1.3rem;
  margin: 2rem auto;
  opacity: 80%;
  font-weight: 600;
}

.header-buttons-container {
  margin: 3rem auto;
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.header-btn {
  display: inline-block;

  padding: 0.8rem 4.3rem;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  text-align: center;
  font-family: 'Noto sans', sans-serif;
}

.contact-btn {
  background-color: #ad1a1a;
  color: #fff;
}

.services-btn {
  background-color: #fff;
  color: #ad1a1a;
  border: 2px solid #ad1a1a;
}

/*************************************** end header ***********************************/

/*************************************** Start Main ******************************/

/* carousel */

.main-title {
  font-weight: 700;
}

/* slideshow */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* about section  */
.about-container {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.about {
  text-align: center;
  background-color: #34496e;
  font-family: 'nunito', sans-serif;
  color: #fff;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  /* border-radius: 5px; */
  width: 50%;
}

.about-title {
  font-size: 2.2rem;
}

.about-description {
  font-size: 1.3rem;
  opacity: 80%;
  line-height: 2rem;
  letter-spacing: 2px;
  width: 80%;
  margin: 0 auto;
}

.about-image {
  width: 50%;
}
/* end  about section  */

/* events */

.event-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

/* Add these styles to your CSS file or styles block in your component */
.swiper-button-next,
.swiper-button-prev {
  color: #ad1a1a; /* Change the color as needed */
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 24px; /* Adjust the font size as needed */
}

/* Optional: Adjust the position of the navigation buttons */
.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
}

/* end events */

/* form */

.reddish-title {
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 2rem;
  font-family: 'noto-sans', sans-serif;
  margin-top: 4rem;
}

.reddish-title::after {
  content: '';
  display: block;
  position: absolute;
  background-color: #ad1a1a;
  height: 6px;
  width: 6%;
}

.contact-subtitle {
  margin-top: 1.5rem;
  margin-left: 2rem;
  font-weight: 700;
  margin-bottom: 0;
  font-size: 1rem;
}

.contact-description {
  margin-top: 0;
  margin-left: 2rem;
  font-weight: 600;
  color: black;
  opacity: 60%;
}

form {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0rem 0rem 0.3rem rgb(233, 233, 233);
  width: 95%;
  margin: 0 auto;
  margin-top: 2.5rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  padding: 1.5rem 3rem;
  position: relative;
}

form label {
  /* border: 1px solid black; */
  margin-top: 1.5em;
  color: black;
  opacity: 60%;
  margin-bottom: 0.5rem;
}

form input {
  margin-top: 0.2rem;
  border-radius: 10px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  padding: 0.6rem 1rem;
}

.textarea-container {
  display: flex;
  flex-direction: column;
}

.message {
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  resize: none;
  font-size: 80%;
  height: 100px;
}

/* .textarea-container textarea {
  border-radius: 10px;
  margin-top: 0.2rem;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  padding: 1rem;
} */

form .submit {
  width: 15%;
  margin: 0 auto;
  margin-top: 2rem;
  margin-bottom: 1rem;
  background-color: #ad1a1a;
  color: white;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  border: none;
}

.modal-container {
  position: absolute;
  box-shadow: 0rem 0rem 0.3rem rgb(233, 233, 233);
  width: 50%;
  margin: 0 auto;
  padding: 4rem 3rem;
  text-align: center;
  display: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
}

.modal-container .close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: none;
  background: none;
  border: none;
}

.modal-container p {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 8rem 1rem;
}

/* partners section */

.partners {
  margin-bottom: 4rem;
}

.partners-description {
  display: inline-block;
  margin-left: 2rem;
  padding-top: 1rem;
  font-weight: 800;
  width: 80%;
  font-size: 1rem;
}

.partener-img-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10%;
  margin: 0 auto;
  margin-top: 2rem;
  width: 80%;
  /* justify-content: center; */
}

.partener-img {
  width: 10%;
}

.partener-img:nth-child(7),
.partener-img:nth-child(6) {
  width: 18%;
}

.map {
  display: block;
  width: 80%;
  margin: 0 auto;
  border-radius: 20px;
  height: 50vh;
  margin-bottom: 8rem;
  margin-top: 5rem;
}

.blue-title {
  font-family: 'Nunito', sans-serif;
}

.blue-title::after {
  content: '';
  display: block;
  position: absolute;
  background-color: #003366;
  height: 0.15%;
  width: 8%;
  border-radius: 10px;
}

footer {
  background: url('../src/images/Footer.svg');
  object-fit: contain;
  background-position: center;
  color: #333333;
  padding: 0 2rem;
}

.footer-title {
  color: #333333;
  font-family: 'Nunito', sans-serif;
  font-weight: 800;
  font-size: 2rem;
  text-wrap: nowrap;
}

.footer-description {
  color: #333;
  opacity: 80%;
  font-size: 1.3rem;
}

.footer-contact-container {
  /* border: 2px solid black; */
  text-align: center;
  width: 50%;
  margin: 4% auto;
}

.footer-items-container {
  display: flex;
  justify-content: space-around;
  gap: 40px;
}

.footer-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1rem;
}

.footer-contact-container {
  position: relative;
}

.footer-contact-title {
  text-align: center;
  position: relative;
}

.footer-contact-title::before,
.footer-contact-title::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 10%;
  height: 5px;
  background-color: #000; /* Adjust the color as needed */
  border-radius: 10px;
}

.footer-contact-title::before {
  left: 18%;
}

.footer-contact-title::after {
  right: 18%;
}

.footer-contact-description {
  text-align: center;
}

/************************************** Medias **************************************/
@media screen and (max-width: 1300px) {
  .about {
    text-align: center;
    background-color: #34496e;
    font-family: 'nunito', sans-serif;
    color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    /* border-radius: 5px; */
    width: 60%;
  }

  .about-title {
    font-size: 2rem;
  }

  .about-description {
    font-size: 1.2rem;
    opacity: 80%;
    line-height: 2rem;
    letter-spacing: 0px;
    width: 80%;
    margin: 0 auto;
  }

  .about-image {
    display: block;
  }
}
@media screen and (max-width: 1200px) {
  .sub-intro {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .header-buttons-container {
    /* border: 1px solid black; */
    justify-content: center;
    gap: 3rem;
  }

  .header-btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 1.5rem 0 !important;
    margin-bottom: 1rem !important;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about {
    text-align: center;
    background-color: #34496e;
    font-family: 'nunito', sans-serif;
    color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    /* border-radius: 5px; */
    width: 50%;
  }

  .about-title {
    font-size: 1.6rem;
  }

  .about-description {
    font-size: 1rem;
    opacity: 80%;
    line-height: 2rem;
    letter-spacing: 1px;
    width: 80%;
    margin: 0 auto;
  }

  .about-image {
    display: block;
  }
  .hidden-mob {
    display: none;
  }

  .hidden-laptop {
    display: block;
  }

  .nav-items {
    display: flex;
    justify-content: flex-end;
  }

  .nav-items li:last-child {
    white-space: nowrap;
  }
  .map {
    display: block;
    height: 20vh;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  footer {
    background-color: #d9d9d9;
    display: flex;
  }

  .footer-logo {
    width: 100px;
  }
}

@media screen and (max-width: 1100px) {
  .sub-intro {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .header-buttons-container {
    /* border: 1px solid black; */
    justify-content: center;
    gap: 3rem;
  }

  .header-btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 1.5rem 0 !important;
    margin-bottom: 1rem !important;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about {
    text-align: center;
    background-color: #34496e;
    font-family: 'nunito', sans-serif;
    color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    width: 100%;
  }

  .about-title {
    font-size: 1.3rem;
  }

  .about-description {
    font-size: 0.7rem;
    opacity: 80%;
    line-height: 1rem;
    letter-spacing: 0px;
    width: 80%;
    margin: 0 auto;
  }

  .about-image {
    display: block;
  }

  .reddish-title {
    font-size: 1.6rem;
  }

  .contact-subtitle .contact-description {
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  form {
    margin-right: 2rem;
  }

  form label:first-child {
    margin-top: 0;
  }

  .modal-container {
    position: absolute;
    box-shadow: 0rem 0rem 0.3rem rgb(233, 233, 233);
    width: 50%;
    margin: 0 auto;
    padding: 0.4rem 0.3rem;
    text-align: center;
    display: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
  }

  .modal-container .close {
    position: absolute;
    top: 0.5rem;
    right: -0.5rem;
    background-color: none;
    background: none;
    border: none;
  }

  .modal-container .close img {
    width: 60%;
  }

  .modal-container p {
    font-size: 1rem;
    font-weight: 700;
    padding: 5rem 3rem;
  }

  .partener-img-container {
    margin-bottom: -2rem;
    justify-content: center;
  }

  .map {
    display: block;
    height: 20vh;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  footer {
    background-color: #d9d9d9;
    display: flex;
  }

  .footer-logo {
    width: 100px;
  }
}

@media screen and (max-width: 1000px) {
  nav {
    padding: 0;
    margin: 0;
  }
  .logo {
    width: 100px;
    margin: 0;
    margin-left: 1rem;
  }
  .medicine {
    width: 35%;
    top: 6vh;
  }
  .nav-items {
    width: 35%;
    margin: 0;
    margin-right: 1rem;
  }
  .nav-items li {
    font-size: 16px;
    margin: 1rem;
  }

  .intro {
    font-size: 1.2rem;
  }

  .sub-intro {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }

  .header-buttons-container {
    /* border: 1px solid black; */
    justify-content: center;
    gap: 2rem;
  }

  .header-btn {
    font-size: 0.8rem;
    padding: 0.3rem 0.6rem;
    margin: 0 !important;
    margin-bottom: 1rem !important;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-title {
    font-size: 1.2rem;
    margin-top: 2.5rem;
  }
  .carousel-indicators {
    width: 50px;
    margin: 0 auto;
    bottom: 20px;
  }
  .about {
    text-align: center;
    background-color: #34496e;
    font-family: 'nunito', sans-serif;
    color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    width: 100%;
  }

  .about-title {
    font-size: 1.3rem;
  }

  .about-description {
    font-size: 0.7rem;
    opacity: 80%;
    line-height: 1rem;
    letter-spacing: 0px;
    width: 80%;
    margin: 0 auto;
  }

  .about-image {
    display: none;
  }

  .reddish-title {
    font-size: 1rem;
  }
  .contact-subtitle {
    font-size: 0.8rem;
  }

  .contact-description {
    font-size: 0.8rem;
  }

  form {
    margin-top: 0;
    padding-top: 0;
    padding: 1.8rem;
    /* margin-right: 2rem; */
    width: 90%;
  }

  form label:first-child {
    margin-top: 0;
  }

  form label {
    font-size: 0.8rem;
  }
  form input {
    font-size: 0.7rem;
  }

  form .submit {
    width: 20%;
  }

  .modal-container {
    position: absolute;
    box-shadow: 0rem 0rem 0.3rem rgb(233, 233, 233);
    width: 80%;
    margin: 0 auto;
    padding: 0.4rem 0.3rem;
    text-align: center;
    display: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
  }

  .modal-container .close {
    position: absolute;
    top: 0.5rem;
    right: -0.5rem;
    background-color: none;
    background: none;
    border: none;
  }

  .modal-container .close img {
    width: 60%;
  }

  .modal-container p {
    font-size: 1rem;
    font-weight: 700;
    padding: 3rem 1rem;
    padding-bottom: 2rem;
  }

  .partners-description {
    font-weight: 800;
    margin-bottom: 0;
    font-size: 0.7rem;
    padding: 0;
    margin-right: 1rem;
    width: 80%;
    padding-top: 1rem;
  }

  .partener-img-container {
    margin-bottom: -2rem;
    justify-content: center;
  }

  .map {
    display: block;
    height: 20vh;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  footer {
    background-color: #d9d9d9;
    display: flex;
  }

  .footer-contact-container {
    display: none;
  }

  .footer-items-container {
    flex-direction: column;
    padding: 2rem 0;
  }
  .footer-title {
    font-size: 1.4rem;
    margin-top: 1.5rem;
  }
  .footer-description {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 600px) {
  nav {
    padding: 0;
    margin: 0;
  }
  .logo {
    width: 60px;
    margin: 0;
    margin-left: 1rem;
  }
  .nav-items {
    width: 30%;
    margin: 0;
    margin-right: 1rem;
    justify-content: flex-end;
  }
  .nav-items li {
    font-size: 10px;
    margin: 0.5rem;
  }

  .medicine {
    position: relative;
    /* display: block; */
    width: 35%;
    top: 20px;
  }

  .intro {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }

  .sub-intro {
    font-size: 0.6rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .header-buttons-container {
    /* border: 1px solid black; */
    justify-content: center;
    gap: 1rem;
  }

  .header-btn {
    font-size: 0.4rem;
    padding: 0.3rem 0.6rem;
    margin: 0 !important;
    margin-bottom: 1rem !important;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-title {
    font-size: 0.9rem;
  }

  .carousel-indicators {
    width: 50px;
    margin: 0 auto;
    bottom: 20px;
  }

  .dot {
    transform: scale(0.8);
  }
  .about {
    text-align: center;
    background-color: #34496e;
    font-family: 'nunito', sans-serif;
    color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    width: 100%;
  }

  .about-title {
    font-size: 1.2rem;
  }

  .about-description {
    font-size: 0.6rem;
    opacity: 80%;
    line-height: 1rem;
    letter-spacing: 0px;
    width: 80%;
    margin: 0 auto;
  }

  .about-image {
    display: none;
  }
  .reddish-title {
    font-size: 0.8rem;
    margin-top: 2rem;
    margin-bottom: 0rem;
    padding-bottom: 0;
    margin-left: 1.5rem;
  }

  .reddish-title::after {
    content: '';
    display: block;
    position: absolute;
    background-color: #ad1a1a;
    height: 3px;
    width: 30px;
  }

  .contact-subtitle {
    /* margin-top: 1rem; */
    margin-left: 1.5rem;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 0;
    padding-top: 0;
    font-size: 0.7rem;
  }

  .contact-description {
    margin-top: 0;
    margin-left: 1.5rem;
    font-weight: 600;
    color: black;
    opacity: 60%;
    font-size: 0.7rem;
    margin-bottom: 1rem;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    padding: 1.3rem 1.2rem;
    /* padding-bottom: 1.rem; */
    width: 86.5%;
  }

  form label {
    /* border: 1px solid black; */
    margin-top: 0;
    margin-bottom: 0;
    color: black;
    opacity: 60%;
    /* margin-bottom: .5rem; */
    font-size: 0.7rem;
    padding: 0;
  }

  form input {
    border-radius: 5px;
    padding: 0.25rem 0.4rem;
    font-size: 0.5rem;
    margin-top: 0.1;
    margin-bottom: 0.5rem;
  }

  .textarea-container {
    display: flex;
    flex-direction: column;
  }

  .textarea-container textarea {
    margin-top: 0.2rem;
    border-radius: 10px;
    height: 80px;
  }

  form .submit {
    margin: 0 auto;
    margin-top: 1rem;
    padding: 0.3rem;

    /* border: 2px solid black; */
  }

  .modal-container {
    position: absolute;
    box-shadow: 0rem 0rem 0.3rem rgb(233, 233, 233);
    width: 80%;
    margin: 0 auto;
    padding: 0.4rem 0.3rem;
    text-align: center;
    display: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
  }

  .modal-container .close {
    position: absolute;
    top: 0.5rem;
    right: -0.5rem;
    background-color: none;
    background: none;
    border: none;
  }

  .modal-container .close img {
    width: 40%;
  }

  .modal-container p {
    font-size: 0.6rem;
    font-weight: 700;
    padding: 3rem 1rem;
    padding-bottom: 2rem;
  }

  .submit {
    font-size: 0.7rem;
  }

  .partners-description {
    margin-left: 1.5rem;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 0.5rem;
    padding: 0;
    margin-right: 1rem;
    width: 80%;
    padding-top: 1rem;
  }

  .partener-img-container {
    justify-content: center;
    margin-bottom: -3rem;
    padding: 0;
  }

  .map {
    display: block;
    height: 20vh;
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  footer {
    background-color: #d9d9d9;
    display: flex;
  }

  .footer-contact-container {
    display: none;
  }

  .footer-items-container {
    flex-direction: column;
  }
  .footer-title {
    font-size: 1.2rem;
  }
  .footer-description {
    font-size: 1rem;
  }
}

@media screen and (max-width: 400px) {
  nav {
    padding: 0 1rem;
    margin: 0;
  }
  .logo {
    width: 55px;
    margin: 0;
    margin-left: 1rem;
  }
  .nav-items {
    width: 30%;
    margin: 0;
    justify-content: flex-end;
  }
  .nav-items li {
    margin: 0 0.4rem;
  }

  .intro {
    margin-top: 0.9rem;
    font-size: 0.7rem;
    margin-bottom: 0;
  }

  .sub-intro {
    font-size: 0.5rem;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }

  .header-buttons-container {
    /* border: 1px solid black; */
    justify-content: center;
    gap: 1rem;
  }

  .header-btn {
    font-size: 0.4rem;
    padding: 0.3rem 0.6rem;
    margin: 0 !important;
    margin-bottom: 1rem !important;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-title {
    margin-top: 1rem;
    font-size: 0.7rem;
    padding: 0;
    margin-bottom: 0.5rem;
  }

  .carousel {
    margin-top: 0;
  }

  .carousel-indicators {
    width: 40px;
    margin: 0 auto;
    bottom: 20px;
  }

  .about-container {
    display: flex;
    justify-content: center;
    /* margin: 0; */
    padding: 0;
    margin: 0 auto;
  }

  .about {
    text-align: center;
    background-color: #34496e;
    font-family: 'nunito', sans-serif;
    color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    width: 100%;
  }

  .about-title {
    font-size: 1rem;
  }

  .about-description {
    font-size: 0.5rem;
    opacity: 80%;
    line-height: 1rem;
    letter-spacing: 0px;
    width: 80%;
    margin: 0 auto;
  }

  .about-image {
    display: none;
  }

  /* end  about section  */

  .reddish-title {
    font-size: 0.7rem;
    margin-top: 2rem;
    margin-left: 1rem;
    margin-bottom: 0rem;
  }

  .reddish-title::after {
    content: '';
    display: block;
    position: absolute;
    background-color: #ad1a1a;
    height: 3px;
    width: 30px;
  }

  .contact-subtitle {
    /* margin-top: 1rem; */
    margin-left: 1rem;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 0.5rem;

    padding: 0;
    margin-top: 0.5rem;
    padding-top: 0;
  }

  .contact-description {
    margin-top: 0;
    margin-left: 1rem;
    font-weight: 600;
    color: black;
    opacity: 60%;
    font-size: 0.5rem;
    margin-bottom: 0;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  form label {
    /* border: 1px solid black; */
    margin-top: 0;
    margin-bottom: 0;
    color: black;
    opacity: 60%;
    /* margin-bottom: .5rem; */
    font-size: 0.6rem;
  }

  form input {
    border-radius: 5px;
    padding: 0.2rem 0.4rem;
    font-size: 0.5rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .textarea-container {
    display: flex;
    flex-direction: column;
  }

  .textarea-container textarea {
    margin-top: 0.2rem;
    border-radius: 10px;
    height: 80px;
  }

  .submit {
    border: 2px solid black;
    font-size: 0.5rem;
  }

  .modal-container {
    position: absolute;
    box-shadow: 0rem 0rem 0.3rem rgb(233, 233, 233);
    width: 80%;
    margin: 0 auto;
    padding: 0.4rem 0.3rem;
    text-align: center;
    display: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 10px;
  }

  .modal-container .close {
    position: absolute;
    top: 0.5rem;
    right: -0.5rem;
    background-color: none;
    background: none;
    border: none;
  }

  .modal-container .close img {
    width: 40%;
  }

  .modal-container p {
    font-size: 0.6rem;
    font-weight: 700;
    padding: 3rem 1rem;
    padding-bottom: 2rem;
  }

  .partners-description {
    display: inline-block;
    margin-left: 1rem;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 0.4rem;
    padding: 0;
    /* margin-top: 5rem; */
    margin-right: 1rem;
    width: 80%;
  }

  .partener-img-container {
    justify-content: center;
    margin-bottom: -3rem;
    padding: 0;
  }

  .map {
    display: block;
    height: 20vh;
    margin: 0 auto;

    margin-bottom: 1rem;
  }

  footer {
    display: flex;
    background: url('../src/images/Footer.svg');
  }

  .footer-contact-container {
    display: none;
  }

  .footer-items-container {
    flex-direction: column;
  }
  .footer-title {
    font-size: 1rem;
    margin: 0 !important;
  }
  .footer-description {
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
  }
}
